import React from "react";            
import {            
 AspectRatio,           
 Box,     
 Center,      
//  Image,           
//  Heading,           
//  chakra,           
 HStack,           
} from "@chakra-ui/react";   
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";



import Iframe from "react-iframe";            
      function ContentText5() {      
       return (     
        <div style={{ backgroundImage: `url(${background})` }}>

        <Box>    
        <HStack>   
                <Center> 
        {/* <chakra.h1 fontSize="lg"> Button Heading </chakra.h1>    
        <chakra.button    
px="6"    
py="2"    
bg="orange.600"    
rounded="md"    
_hover={{ bg: "orange.300" }}    
>
Click for Content   
        </chakra.button>    
<br/>               
<br/>               
        <Box sx={{ "--my-color": "#53c8c4" }}>    
        <Heading color="var(--my-color)" size="lg">    
Welcome to MetaGame   
        </Heading>    
        </Box>    
<br/>               
<br/>               
        <Box borderWidth={2} borderColor="purple.500" p={5} className="my-box">    
        <Heading size="lg" color="blue.500">    
From one Color to {}   
        <Box    
as="span"    
color="red.500"    
sx={{    
".my-box:hover &": {    
     color: "green.500",       
},
}}
>
Another   
        </Box>    
        </Heading>    
        </Box>    
<br/>               
<br/>               
        <AspectRatio maxW="800px" ratio={1.5}>    
<Image    
src=" https://bafybeibopr3dkfuddq5mhznnzktqymrz5ghzt4rc7jxxpgl2dtgerseqyq.ipfs.infura-ipfs.io "  
alt=" MetaGame "  
objectFit="cover"    
/>
        </AspectRatio>     */}
<br/>               
<br/>               
        <Iframe    
url="https://bafybeigm4nyozw4tdvmtj74lcxhwvpbsrcob27q44hua5eoijkwkfv6bwy.ipfs.infura-ipfs.io/"    
width="850px"    
height="450px"    
id="myId"    
className="myClassname"    
display="initial"    
position="relative"    
/>
<br/>               
<br/>               
        <AspectRatio maxW="2400px" ratio={7 / 3}>    
        <iframe    
title=" MetaGame "  
src=" https://bafybeigm4nyozw4tdvmtj74lcxhwvpbsrcob27q44hua5eoijkwkfv6bwy.ipfs.infura-ipfs.io/ "  
allowFullScreen   
/>
        </AspectRatio>    
<br/>               
<br/>               
        <AspectRatio ratio={16 / 9}>    
        <iframe    
title=" OpusOneMap "  
src=" https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=7900%20St%20Helena%20Hwy,%20Oakville,%20CA%2094562+(Opus%20One,%20Oakville)&amp;t=k&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed "  
alt=" MetaGame "  
/>
        </AspectRatio>  
        </Center>  
        </HStack>    
        </Box>   
        </ div>
 
);
}
export default ContentText5;              
