import { useQuery } from "@apollo/react-hooks";


// UNCOMMENT FOR DEMO vvv
// import { Contract } from "@ethersproject/contracts";
// import { getDefaultProvider } from "@ethersproject/providers";
// import { addresses, abis } from "@project/contracts";


import React, { useEffect, useState } from "react";
// import React, { useState } from "react";
// import { Body, Button, Header, Image, Link } from "./components";
// import logo from "./metagame-diamond-logo.png";
import useWeb3Modal from "./hooks/useWeb3Modal";
import GET_TRANSFERS from "./graphql/subgraph";

// import DarkModeToggle from "react-dark-mode-toggle";
import "./App.css";
import { actions, StateProvider, loadingState } from "./state";
import {
  Button,
  ChakraProvider,
  Box,
  VStack,
  StackDivider,
  Text,
} from "@chakra-ui/react";

import {
  Redirect,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";
// import { BrowserRouter as Router } from "react-router-dom";


import Systems from "./components/systems";
import DatabaseForm from "./components/databaseForm";
import DatabaseLocal from "./components/databaseLocal";
import Filters from "./components/filters";
import Web3ContextProvider from "./libs/Web3Context";
// import DonutFooter from "./components/ipfsTools";
import DonutFooter from "./components/donutFooter";
import DonutFooter2 from "./components/donutFooter2";

import AuthenticationForm1 from "./components/authenticationForm1";
import AuthenticationForm3 from "./components/authenticationForm3";
import Blog1 from "./components/blog1";
// import ButtonsBar1 from "./components/buttonsBar1";
import CardContact1 from "./components/cardContact1";
import CardTextPost from "./components/cardTextPost";
import ContentText3 from "./components/contentText3";
import ContentText5 from "./components/contentText5";
import Drawer1 from "./components/drawer1";
import DrawerGig from "./components/drawerGig";
import DrawerResolve from "./components/drawerResolve";
import Feature5 from "./components/feature5";

import FeedbackLayout5 from "./components/feedbackLayout5";
import FeedbackLayout6 from "./components/feedbackLayout6";
import FeedbackLayout7 from "./components/feedbackLayout7";
import FeedbackLayout8 from "./components/feedbackLayout8";
// import Footer from "./components/footer";
import Footer6 from "./components/footer6";
import Footer9 from "./components/footer9";
import FormLayout11 from "./components/formLayout11";
import FormLayout12 from "./components/formLayout12";
import FormLayout13 from "./components/formLayout13";
import FormLayout16 from "./components/formLayout16";
import FormLayout4 from "./components/formLayout4";
import FormLayout5 from "./components/formLayout5";
// import FormPasswordInput from "./components/formPasswordInput";

import GridLayout1 from "./components/gridLayout1";
import GridLayout2 from "./components/gridLayout2";
import GridLayout3 from "./components/gridLayout3";
import GridLayout4 from "./components/gridLayout4";
import GridLayout5 from "./components/gridLayout5";
import GridLayout6 from "./components/gridLayout6";
import GridLayout7 from "./components/gridLayout7";
import GridLayout8 from "./components/gridLayout8";
import GridLayout9 from "./components/gridLayout9";
import GridLayout10 from "./components/gridLayout10";
import GridLayout11 from "./components/gridLayout11";

import Header0 from "./components/header0";
import Header1 from "./components/header1";
import Hero1 from "./components/hero1";
import HookDrawer from "./components/hookDrawer";
import HookModal from "./components/hookModal";
// import IconsChakraUI from "./components/iconsChakraUI";
// import IconsFontAwesome1 from "./components/iconsFontAwesome1";
// import IconsFontAwesome2 from "./components/iconsFontAwesome2";
// import IconsFontAwesome3 from "./components/iconsFontAwesome3";
// import IconsFontAwesomeEmotions from "./components/iconsFontAwesomeEmotions";
// import IconsMoon from "./components/iconsMoon";

import Images1 from "./components/images1";
import Links1 from "./components/links1";
import MenuLayout2 from "./components/menuLayout2";
import ModalLayout4 from "./components/modalLayout4";
import ObjectCreation from "./components/objectCreation";
import ObjectCreation1 from "./components/objectCreation1";

import Popover4 from "./components/popover4";
import Popover7 from "./components/popover7";
import Pricing1 from "./components/pricing1";
import ProfileLayout1 from "./components/profileLayout1";
import SigninButton from "./components/signinButton";
// import SigninButtons from "./components/signinButtons";
// import Spinner0 from "./components/spinner0";

import Tabs7 from "./components/tabs7";
import Tabs8 from "./components/tabs8";
import Testimonial1 from "./components/testimonial1";

const initialState = {
  user: null,
  db: null,
  entries: [],
  dbGuide: null,
  entriesGuide: [],
  dbDAGtest: null,
  entriesDAGtest: [],
  dbUsers: null,
  entriesUsers: [],
  programs: [],
  orbitdbStatus: "Starting",
  ipfsStatus: "Starting",
  program: false,
  loading: {
    programs: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.USER.SET_USER:
      return {
        ...state,
        user: action.publicKey,
      };
    case actions.SYSTEMS.SET_ORBITDB:
      return {
        ...state,
        orbitdbStatus: action.orbitdbStatus,
      };
    case actions.SYSTEMS.SET_IPFS:
      return {
        ...state,
        ipfsStatus: action.ipfsStatus,
      };
    case actions.DB.SET_DB:
      return {
        ...state,
        db: action.db,
        entries: action.entries,
      };
    case actions.DBGUIDE.SET_DBGUIDE:
      return {
        ...state,
        dbGuide: action.db,
        entriesGuide: action.entries,
      };
    case actions.DBDAGTEST.SET_DBDAGTEST:
      return {
        ...state,
        dbDAGtest: action.db,
        entriesDAGtest: action.entries,
      };
    case actions.DBUSERS.SET_DBUSERS:
      return {
        ...state,
        dbUsers: action.db,
        entriesUsers: action.entries,
      };
    case actions.PROGRAMS.SET_PROGRAM:
      return {
        ...state,
        program: action.program,
      };
    case actions.PROGRAMS.SET_PROGRAM_LOADING:
      return {
        ...state,
        program: loadingState,
      };
    case actions.PROGRAMS.SET_PROGRAMS:
      return {
        ...state,
        programs: action.programs,
      };
    case actions.PROGRAMS.SET_PROGRAMS_LOADING:
      return {
        ...state,
        loading: { ...state.loading, programs: action.loading },
      };
    default:
      return state;
  }
};

// UNCOMMENT FOR DEMO vvv
// async function readOnChainData() {
//   // Should replace with the end-user wallet, e.g. Metamask
//   const defaultProvider = getDefaultProvider();
//   // Create an instance of an ethers.js Contract
//   // Read more about ethers.js on https://docs.ethers.io/v5/api/contract/contract/
//   const ceaErc20 = new Contract(
//     addresses.ceaErc20,
//     abis.erc20,
//     defaultProvider
//   );
//   // A pre-defined address that owns some CEAERC20 tokens
//   const tokenBalance = await ceaErc20.balanceOf(
//     "0x3f8CB69d9c0ED01923F11c829BaE4D9a4CB6c82C"
//   );
//   console.log({ tokenBalance: tokenBalance.toString() });
// }
//  UNCOMMENT FOR DEMO ^^^




function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {
  const [account, setAccount] = useState("");
  const [rendered, setRendered] = useState("");

  useEffect(() => {
    async function fetchAccount() {
      try {
        if (!provider) {
          return;
        }

        // Load the user's accounts.
        const accounts = await provider.listAccounts();
        setAccount(accounts[0]);

        // Resolve the ENS name for the first account.
        const name = await provider.lookupAddress(accounts[0]);

        // Render either the ENS name or the shortened account address.
        if (name) {
          setRendered(name);
        } else {
          setRendered(account.substring(0, 6) + "..." + account.substring(36));
        }
      } catch (err) {
        setAccount("");
        setRendered("");
        console.error(err);
      }
    }
    fetchAccount();
  }, [account, provider, setAccount, setRendered]);

  return (
    <Button
      bg={"green.500"}
      display={{ base: "none", md: "inline-flex" }}
      size={"sm"}
      fontSize={"sm"}
      fontWeight={600}
      color={"white"}
      ml={"4"}
      mr={"2"}
      onClick={() => {
        if (!provider) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
        }
      }}
    >
      {rendered === "" && "Connect Wallet"}
      {rendered !== "" && rendered}
    </Button>
  );
}

function App() {
  const { loading, error, data } = useQuery(GET_TRANSFERS);
  const [provider, loadWeb3Modal, logoutOfWeb3Modal] = useWeb3Modal();

  React.useEffect(() => {
    if (!loading && !error && data && data.transfers) {
      console.log({ transfers: data.transfers });
    }
  }, [loading, error, data]);

  return (
    <div>
      <StateProvider initialState={initialState} reducer={reducer}>
        <ChakraProvider>
          <Web3ContextProvider>
            <Box backgroundColor="#303030" color="white">
              <Header1 w="100%" />
              <MenuLayout2 w="100%" />
              <SigninButton w="100%" />

              <Box mb="2" mt="2">
                <WalletButton
                  provider={provider}
                  loadWeb3Modal={loadWeb3Modal}
                  logoutOfWeb3Modal={logoutOfWeb3Modal}
                />



{/* UNCOMMENT FOR DEMO vvv */}
                {/* <Button
                  onClick={() => readOnChainData()}
                  display={{ base: "none", md: "inline-flex" }}
                  size={"sm"}
                  fontSize={"sm"}
                  fontWeight={600}
                  color={"white"}
                  bg={"green.500"}
                  _hover={{
                    bg: "green.800",
                  }}
                >
                  Read On-Chain Balance
                </Button> */}
{/* UNCOMMENT FOR DEMO ^^^ */}


              </Box>

              <Router>
                <Box></Box>
                <VStack
                  divider={<StackDivider borderColor="gray.200" />}
                  spacing={4}
                  align="stretch"
                >
                  {/* TRANSACTIONS */}
                <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part II: Smart-Contract Transactions
                  </Text>


                  <Tabs7 w="100%" />
                  <Drawer1 w="100%" />
                  <ObjectCreation w="100%" />
                  <AuthenticationForm1 w="100%" />
                  
                  {/* HERO */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part III: Hero Promo
                  </Text>
                  <Hero1 w="100%" />
                  <Blog1 w="100%" />
                  <DrawerGig w="100%" />
                  <DrawerResolve w="100%" />
                  <Feature5 w="100%" />
                  <Pricing1 w="100%" />
                  <AuthenticationForm1 w="100%" />
                  {/* FIND A PLAYER */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part IV: Find a Player
                  </Text>

                  <CardContact1 w="100%" />
                  <Tabs8 w="100%" />
                  <Images1 w="100%" />
                  <Testimonial1 w="100%" />
                  <AuthenticationForm1 w="100%" />

                  {/* GIG BUILDING */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part V: Build a Gig
                  </Text>

                  <FeedbackLayout8 w="100%" />
                  <HookDrawer w="100%" />
                  <AuthenticationForm3 w="100%" />
                  <Popover7 w="100%" />
                  <FormLayout4 w="100%" />
                  <FormLayout11 w="100%" />
                  <FormLayout16 w="100%" />
                  <FormLayout5 w="100%" />
                  <ObjectCreation1 w="100%" />
                  <FormLayout13 w="100%" />
                  <FormLayout12 w="100%" />
                  <AuthenticationForm1 w="100%" />
                  {/* PLAYER OR APP GALLERY */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part VI: Player and App Gallery
                  </Text>
                  <CardTextPost w="100%" />
                  <ContentText3 w="100%" />
                  <AuthenticationForm1 w="100%" />
                  {/* INSTRCTION MANUAL */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part VII: Meta-Collab User's Guide
                  </Text>
                  <FeedbackLayout5 w="100%" />
                  <FeedbackLayout6 w="100%" />
                  <FeedbackLayout7 w="100%" />
                  <HookModal w="100%" />
                  <ModalLayout4 w="100%" />
                  <Popover4 w="100%" />
                  <AuthenticationForm1 w="100%" />
                  {/* PLAYER PROFILE */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part VIII: Player's Profile
                  </Text>
                  <ProfileLayout1 w="100%" />

                  <AuthenticationForm1 w="100%" />
                  {/* FOOTER SECTION */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part IX: Footer Sections
                  </Text>
                  <Links1 w="100%" />
                  <Footer6 w="100%" />
                  <Footer9 w="100%" />
                  <AuthenticationForm1 w="100%" />

                  {/* CONTENT IMAGES */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part X: Content Images
                  </Text>
                  <GridLayout1 w="100%" />
                  <GridLayout2 w="100%" />
                  <GridLayout3 w="100%" />
                  <GridLayout4 w="100%" />
                  <GridLayout5 w="100%" />
                  <GridLayout6 w="100%" />
                  <GridLayout10 w="100%" />
                  <GridLayout11 w="100%" />
                  <AuthenticationForm1 w="100%" />
                  {/* CONTENT TEXT */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part XI: Content Text
                  </Text>
                  <GridLayout9 w="100%" />
                  <GridLayout7 w="100%" />
                  <GridLayout8 w="100%" />
                  <AuthenticationForm1 w="100%" />

                  {/* DASHBOARD TOOLS */}
                  <hr />
                  <Text
                    mt="30px" 
                    fontWeight="bold"
                    textTransform="uppercase"
                    fontSize="3rem"
                    letterSpacing="wide"
                    color="yellow"
                    align="center"
                  >
                    UX Part XII: Demo Videos
                  </Text>
                  <ContentText5 w="100%" />
                  <Header0 w="100%" />
                  <DonutFooter w="100%" />
                  <DonutFooter2 w="100%" />
                  <Systems w="100%" />

                  {/* <Spinner0 w="100%" /> */}
                  {/* <Header w="100%" /> */}
                  {/* <IconsChakraUI w="100%" /> */}
                  {/* <IconsFontAwesome1 w="100%" />  */}
                  {/* <IconsFontAwesome2 w="100%" />  */}
                  {/* <IconsFontAwesome3 w="100%" />  */}
                  {/* <IconsFontAwesomeEmotions w="100%" />  */}
                  {/* <IconsMoon w="100%" />  */}
                  {/* <FormPasswordInput w="100%" /> */}
                  {/* <SigninButtons w="100%" /> */}
                  {/* <Footer w="100%" /> */}
                  {/* <ButtonsBar1 w="100%" /> */}

                  <Switch>
                    <Route
                      exact
                      path="/sharedDatabases"
                      component={DatabaseForm}
                    />
                    <Route
                      exact
                      path="/localDatabases"
                      component={DatabaseLocal}
                    />
                    <Route path="/" component={Filters} />
                    <Redirect to="/" />
                  </Switch>

                  {/* <br />
                  <Toast w="100%" />
                  <br /> */}

                  <hr />
                </VStack>
              </Router>

              {/* <Box>
              <DarkModeToggle
                align="center"
                onChange={setIsDarkMode}
                checked={isDarkMode}
                size={80}
              />
            </Box> */}
            </Box>
          </Web3ContextProvider>
        </ChakraProvider>
      </StateProvider>
    </div>
  );
}

export default App;
