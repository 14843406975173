import React from "react";
import {
  Box,
  Image,
  Text,
  Link,
  Editable,
  EditableInput,
  EditablePreview,
  Heading,
  Badge,
  Flex,
  WrapItem,
  Stack,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatGroup,
  Tag,
  TagLabel,
  TagLeftIcon,
  TagRightIcon,
  HStack,
  Avatar,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Wrap,
  IconButton,
  Portal,
  Button,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

import { FaEdit, FaBitcoin, FaEthereum } from "react-icons/fa";

import {
  AddIcon,
  HamburgerIcon,
  ChevronDownIcon,
  RepeatIcon,
  LinkIcon,
} from "@chakra-ui/icons";

import { ImStarFull } from "react-icons/im";
import {
  WiAlien,
  WiBarometer,
  WiEarthquake,
  WiFire,
  WiSolarEclipse,
  WiLightning,
  WiStars,
} from "react-icons/wi";

import artistsbadge from "../images/metagame/badge-artists-500x-2022.png";
import buildersbadge from "../images/metagame/badge-builders-500x-2022.png";
import designersbadge from "../images/metagame/badge-designers-500x-2022.png";
import fairiesbadge from "../images/metagame/badge-fairies-500x-2022.png";
import headhuntersbadge from "../images/metagame/badge-headhunter-500x-2022.png";
import innkeepersbadge from "../images/metagame/badge-innkeepers-500x-2022.png";
import necromancersbadge from "../images/metagame/badge-necromancers-500x-2022.png";
import shillersbadge from "../images/metagame/badge-shillers-500x-2022.png";
import writersbadge from "../images/metagame/badge-writers-500x-2022.png";

// import smartlaweb3skills from "../images/lexdao/smartlaw-web3skills-white.png";
// import blockchains from "../images/lexdao/008-compass.svg";
// import enforcement from "../images/lexdao/009-sheriff-badge.svg";
// import airdropsurprise from "../images/lexdao/012-trick.svg";
// import evidencer from "../images/lexdao/024-candy.svg";
// import judiciary from "../images/lexdao/027-scale.svg";
// import bughunters from "../images/lexdao/029-antivirus.svg";
// import donuts from "../images/lexdao/054-donut-3.svg";
// import artists from "../images/lexdao/080-pallete.svg";
// import developers from "../images/lexdao/088-builder-1.svg";
// import rewards from "../images/lexdao/099-magic-wand-2.svg";
// import recruiters from "../images/lexdao/110-headhunter-2.svg";
// import marketers from "../images/lexdao/116-megaphone-2.svg";
// import designers from "../images/lexdao/118-graphic-designer.svg";
// import trees from "../images/lexdao/153-magnolia.svg";
// import superchargers from "../images/lexdao/210-zeus-1.svg";
// import advocates from "../images/lexdao/212-zeus-3.svg";
// import justice from "../images/lexdao/216-justice.svg";
// import resolvers from "../images/lexdao/217-auction.svg";
// import projectmanagers from "../images/lexdao/218-target-1.svg";
// import authenticators from "../images/lexdao/219-compliant.svg";
// import governors from "../images/lexdao/220-governance.svg";
// import tealeaves from "../images/lexdao/223-tea-leaf.svg";
// import documents from "../images/lexdao/225-search.svg";
// import strategists from "../images/lexdao/226-strategy.svg";
// import jurors from "../images/lexdao/229-handshake.svg";
// import policymakers from "../images/lexdao/229-handshake.svg";
// import smartlaw from "../images/lexdao/235-trident-7.svg";

function ProfileLayout1() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>
      <Box p={4} display={{ md: "flex" }}>
        <Box flexShrink={0}>
          <Image
            borderRadius="lg"
            maxW="400px"
            width={{ md: 40 }}
            src=" https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io
 "
            alt=" MetaGame "
          />
        </Box>
        <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
          <Text
            fontWeight="bold"
            textTransform="uppercase"
            fontSize="4xl"
            letterSpacing="wide"
            color="yellow"
          >
            Profile Layout
          </Text>
          <HStack>
            <Menu>
              <MenuButton
                variant="outline"
                color="cyan"
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                MetaGame Actions
              </MenuButton>
              <MenuList>
                <MenuItem color="red"> Download </MenuItem>
                <MenuItem color="red"> Create a Copy </MenuItem>
                <MenuItem color="red"> Mark as Draft </MenuItem>
                <MenuItem color="red"> Delete </MenuItem>
                <MenuItem color="red"> Attend a Workshop </MenuItem>
              </MenuList>
            </Menu>
            <br />
            <br />
            <Menu>
              {({ isOpen }) => (
                <>
                  <MenuButton
                    variant="outline"
                    color="orange"
                    isActive={isOpen}
                    as={Button}
                    rightIcon={<ChevronDownIcon />}
                  >
                    {isOpen ? "Close - State" : "Open - State"}
                  </MenuButton>
                  <MenuList>
                    <MenuItem color="orange"> Download </MenuItem>
                    <MenuItem
                      color="orange"
                      onClick={() => alert("Confirm the Choice")}
                    >
                      Create a Copy
                    </MenuItem>
                  </MenuList>
                </>
              )}
            </Menu>

            <br />
            <Menu>
              <MenuButton
                variant="outline"
                colorScheme="purple"
                as={Button}
                rightIcon={<ChevronDownIcon />}
              >
                Groups
              </MenuButton>
              <MenuList>
                <MenuItem color="blue" minH="40px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src={innkeepersbadge}
                    alt=" Innkeeers Guild "
                    mr="12px"
                  />
                  <span> Innkeepers Guild </span>
                </MenuItem>
                <MenuItem color="blue" minH="40px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src={buildersbadge}
                    alt=" Builders Guild "
                    mr="12px"
                  />
                  <span> Builders Guild </span>
                </MenuItem>
                <MenuItem color="blue" minH="48px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src={designersbadge}
                    alt=" Designers Guild "
                    mr="12px"
                  />
                  <span> Designers Guild </span>
                </MenuItem>
                <MenuItem color="blue" minH="48px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src={writersbadge}
                    alt=" Writers Guild "
                    mr="12px"
                  />
                  <span> Writers Guild </span>
                </MenuItem>
                <MenuItem color="blue" minH="48px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src={artistsbadge}
                    alt=" Artists Guild "
                    mr="12px"
                  />
                  <span> Artists Guild </span>
                </MenuItem>
                <MenuItem color="blue" minH="48px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src={fairiesbadge}
                    alt=" Fairies Guild "
                    mr="12px"
                  />
                  <span> Fairies Guild </span>
                </MenuItem>
                <MenuItem color="blue" minH="40px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src={shillersbadge}
                    alt=" Shillers Guild "
                    mr="12px"
                  />
                  <span> Shillers Guild </span>
                </MenuItem>
                <MenuItem color="blue" minH="48px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src={headhuntersbadge}
                    alt=" Headhunters Guild "
                    mr="12px"
                  />
                  <span> Headhunters Guild </span>
                </MenuItem>
                <MenuItem color="blue" minH="40px">
                  <Image
                    boxSize="2rem"
                    borderRadius="full"
                    src={necromancersbadge}
                    alt=" Necromancers Guild "
                    mr="12px"
                  />
                  <span> Necromancers Guild </span>
                </MenuItem>
              </MenuList>
            </Menu>
            <br />
            <Menu>
              <MenuButton as={Button} colorScheme="purple">
                Member Profile
              </MenuButton>
              <MenuList color="blue">
                <MenuGroup title=" Profile">
                  <MenuItem> My Account </MenuItem>
                  <MenuItem> Payments </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title=" Help">
                  <MenuItem> Docs </MenuItem>
                  <MenuItem> FAQ </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
            <br />
            <Menu>
              <MenuButton as={Button} colorScheme="pink">
                Patron Profile
              </MenuButton>
              <MenuList color="blue">
                <MenuGroup title=" Profile">
                  <MenuItem> My Account </MenuItem>
                  <MenuItem> Payments </MenuItem>
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title=" Help">
                  <MenuItem> Docs </MenuItem>
                  <MenuItem> FAQ </MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
            <br />
            <Menu closeOnSelect={false}>
              <MenuButton as={Button} colorScheme="blue">
                MenuItem
              </MenuButton>
              <MenuList minWidth="240px">
                <MenuOptionGroup defaultValue="asc" title=" Order" type="radio">
                  <MenuItemOption value="asc"> Ascending </MenuItemOption>
                  <MenuItemOption value="desc"> Descending </MenuItemOption>
                </MenuOptionGroup>
                <MenuDivider />
                <MenuOptionGroup title=" Country" type="checkbox">
                  <MenuItemOption value="email"> Email </MenuItemOption>
                  <MenuItemOption value="phone"> Phone </MenuItemOption>
                  <MenuItemOption value="country"> Country </MenuItemOption>
                </MenuOptionGroup>
              </MenuList>
            </Menu>
            <br />
            <Menu>
              <MenuButton color="yellow"> Open menu </MenuButton>
              <Portal>
                <MenuList color="blue">
                  <MenuItem> Menu 1 </MenuItem>
                  <MenuItem> New Window </MenuItem>
                  <MenuItem> Open Closed Tab </MenuItem>
                  <MenuItem> Open File </MenuItem>
                </MenuList>
              </Portal>
            </Menu>
            <br />
            <Menu>
              <MenuButton
                as={IconButton}
                aria-label=" Options "
                icon={<HamburgerIcon />}
                variant="outline"
                colorScheme="blue"
                color="cyan"
              />
              <MenuList color="blue">
                <MenuItem icon={<AddIcon />} command="⌘T">
                  New Tab
                </MenuItem>
                <MenuItem icon={<LinkIcon />} command="⌘N">
                  New Window
                </MenuItem>
                <MenuItem icon={<RepeatIcon />} command="⌘⇧N">
                  Open Closed Tab
                </MenuItem>
                <MenuItem icon={<FaEdit />} command="⌘O">
                  Open File...
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
          <br />
          <Center>
            <Wrap>
              <WrapItem>
                <Avatar
                  name=" Innkeepers Guild "
                  size="lg"
                  bg="gray.500"
                  src={innkeepersbadge}
                />
              </WrapItem>
              <WrapItem>
                <Avatar
                  name=" Builders Guild "
                  size="lg"
                  bg="gray.500"
                  src={buildersbadge}
                />
              </WrapItem>
              <WrapItem>
                <Avatar
                  name=" Designers Guild "
                  size="lg"
                  bg="gray.500"
                  src={designersbadge}
                />
              </WrapItem>
              <WrapItem>
                <Avatar
                  name=" Writers Guild "
                  size="lg"
                  bg="gray.500"
                  src={writersbadge}
                />
              </WrapItem>
              <WrapItem>
                <Avatar
                  name=" Artists Guild "
                  size="lg"
                  bg="gray.500"
                  src={artistsbadge}
                />
              </WrapItem>
              <WrapItem>
                <Avatar
                  name=" Fairies Guild "
                  size="lg"
                  bg="gray.500"
                  src={fairiesbadge}
                />
              </WrapItem>
              <WrapItem>
                <Avatar
                  name=" Shillers Guild "
                  size="lg"
                  bg="gray.500"
                  src={shillersbadge}
                />
              </WrapItem>
              <WrapItem>
                <Avatar
                  name=" Headhunters Guild "
                  size="lg"
                  bg="gray.500"
                  src={headhuntersbadge}
                />
              </WrapItem>
              <WrapItem>
                <Avatar
                  name=" Necromancers Guild "
                  size="lg"
                  bg="gray.500"
                  src={necromancersbadge}
                />
              </WrapItem>
            </Wrap>
          </Center>
          <br />
          <Text mt={2} fontSize="3xl" color="gray.100">
            Click to edit.
          </Text>{" "}
          <Editable defaultValue=" Type your message here:">
            <EditablePreview />
            <EditableInput />
          </Editable>
          <br />
          <Link
            mt={1}
            display="block"
            fontSize="lg"
            lineHeight="normal"
            fontWeight="semibold"
            href=" # "
          >
            Finding Members and Patrons for MetaGame
          </Link>
          <Text mt={2} color="gray.100">
            Getting a new organization off the ground takes patience. Here are
            some ways MetaGame members are found.
          </Text>{" "}
          <Stack direction="row">
            <Badge p={"1"} variant="solid" colorScheme="red">
              <Text fontSize="xs"> Skill 1 </Text>
            </Badge>
            <Badge p={"1"} variant="solid" colorScheme="orange">
              <Text fontSize="xs"> Skill 2 </Text>
            </Badge>
            <Badge p={"1"} variant="solid" colorScheme="yellow">
              <Text fontSize="xs"> Skill 3 </Text>
            </Badge>
            <Badge p={"1"} variant="solid" colorScheme="green">
              <Text fontSize="xs"> Skill 4 </Text>
            </Badge>
            <Badge p={"1"} variant="solid" colorScheme="teal">
              <Text fontSize="xs"> Skill 5 </Text>
            </Badge>
            <Badge p={"1"} variant="solid" colorScheme="cyan">
              <Text fontSize="xs"> Skill 6 </Text>
            </Badge>
            <Badge p={"1"} variant="solid" colorScheme="blue">
              <Text fontSize="xs"> Skill 7 </Text>
            </Badge>
            <Badge p={"1"} variant="solid" colorScheme="purple">
              <Text fontSize="xs"> Skill 8 </Text>
            </Badge>
            <Badge p={"1"} variant="solid" colorScheme="pink">
              <Text fontSize="xs"> Skill 9 </Text>
            </Badge>
            <Badge p={"1"} variant="solid" colorScheme="blackAlpha">
              <Text fontSize="xs"> Skill 10 </Text>
            </Badge>
            <Badge p={"1"} variant="outline" colorScheme="blue">
              Rogue Player
            </Badge>
          </Stack>
          <br />
          <Flex>
            <WrapItem>
              <Avatar
                size="xl"
                name=" Scott Stevenson "
                src=" https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/ "
              />{" "}
            </WrapItem>
            <Box ml="3">
              <Text fontWeight="bold">
                Scott Stevenson
                <Badge ml="1" colorScheme="green">
                  tenfinney
                </Badge>
              </Text>
              <Text fontSize="sm"> Web3 Legal Engineering </Text>
            </Box>
          </Flex>
          <br />
          <Text fontSize="xl" fontWeight="bold">
            Scott Stevenson
            <Badge ml="1" fontSize="0.8em" colorScheme="green">
              <Text fontSize="sm"> Tenfinney </Text>
            </Badge>
          </Text>
          <br />
          <Menu>
            <MenuButton
              variant="outline"
              colorScheme="messenger"
              px={4}
              py={2}
              transition="all 0.2s"
              borderRadius="md"
              borderWidth="1px"
              _hover={{ bg: "gray.400" }}
              _expanded={{ bg: "blue.400" }}
              _focus={{ boxShadow: "outline" }}
            >
              File Management
              <ChevronDownIcon />
            </MenuButton>
            <MenuList>
              <MenuItem color="blue"> New File </MenuItem>
              <MenuItem color="blue"> New Window </MenuItem>
              <MenuDivider />
              <MenuItem color="blue"> Open... </MenuItem>
              <MenuItem color="blue"> Save File </MenuItem>
            </MenuList>
          </Menu>
          <br />
          <br />
          <Heading p={"5"}>Player Profile Highlights </Heading>
          <UnorderedList p={"5"} ml="5">
            <ListItem> MetaGame Player Highlight 1 </ListItem>
            <ListItem> MetaGame Player Highlight 2</ListItem>
            <ListItem> MetaGame Player Highlight 3</ListItem>
            <ListItem> MetaGame Player Highlight 4</ListItem>
          </UnorderedList>
          <br />
          <OrderedList p={"5"} ml="5">
            <ListItem> MetaGame Season 1 Highlights </ListItem>
            <ListItem> MetaGame Season 2 Highlights </ListItem>
            <ListItem> MetaGame Season 3 Highlights </ListItem>
            <ListItem> MetaGame Season 4 Highlights </ListItem>
          </OrderedList>
          <br />
          <br />
          <List spacing={1}>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={WiBarometer} w={20} h={20} color="cyan.300" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={WiEarthquake} w={20} h={20} color="lime" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={WiSolarEclipse} w={20} h={20} color="yellow" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={WiFire} w={20} h={20} color="orange" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={WiLightning} w={20} h={20} color="blue.200" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={WiStars} w={20} h={20} color="magenta" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={WiAlien} w={20} h={20} color="green.200" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
          </List>
          <br />
          <Heading p={"5"}>Player Gig Dashboard </Heading>
          <Box ml={"50px"} >
            <Stat>
              <StatLabel> Gig Fee </StatLabel>
              <StatNumber> 
              <Text color="cyan" >
                5,000 MATIC</Text> 
              </StatNumber>
              <StatHelpText> Total time allocated: 50,000 blocks </StatHelpText>
            </Stat>
            <br />
            <Stat>
              <StatLabel> Gig Status </StatLabel>
              <StatNumber> 
              <Text color="lime">ACTIVE</Text>
              </StatNumber>
              <StatHelpText> Block time remaing: 7,500 blocks </StatHelpText>
            </Stat>
            <br />
            <StatGroup>
              <Stat>
                <StatLabel color="red" > Lock Fee </StatLabel>
                <StatNumber> 
                <Text color="red">100 MATIC</Text> 
                </StatNumber>
                <StatHelpText>
                  <StatArrow type="decrease" />
                  <Text>100 MATIC Non-Refundable Resolver Fee</Text>
                </StatHelpText>
              </Stat>
              <Stat color={"lime"} >
                <StatLabel> Resolver Fee </StatLabel>
                <StatNumber> 
                  <Text color="lime">100 MATIC</Text> 
                  </StatNumber>
                <StatHelpText>
                  <StatArrow type="increase" />
                  <Text color="yellow.400">1,500 MATIC half-day resolution</Text>
                </StatHelpText>
              </Stat>
            </StatGroup>
            <br />
            <br />
            <br />
            <HStack spacing={4}>
              {["lg", "lg", "lg"].map((size) => (
                <Tag
                  size={size}
                  key={size}
                  variant="solid"
                  colorScheme="yellow"
                >
                  <TagLeftIcon boxSize="12px" as={ImStarFull} />
                  <TagLabel> Member </TagLabel>
                </Tag>
              ))}
            </HStack>
            <br />
            <HStack spacing={4}>
              {["lg", "lg", "lg"].map((size) => (
                <Tag size={size} key={size} variant="solid" colorScheme="blue">
                  <TagLabel> Ethereum </TagLabel>
                  <TagRightIcon as={FaEthereum} />
                </Tag>
              ))}
            </HStack>
            <br />
            <HStack spacing={4}>
              {["md", "md", "md"].map((size) => (
                <Tag
                  size={size}
                  key={size}
                  variant="solid"
                  colorScheme="facebook"
                >
                  <TagLabel> Patron </TagLabel>
                  <TagRightIcon as={WiSolarEclipse} />
                </Tag>
              ))}
            </HStack>
            <br />
            <HStack spacing={4}>
              {["sm", "sm", "sm"].map((size) => (
                <Tag
                  size={size}
                  key={size}
                  variant="subtle"
                  colorScheme="whiteAlpha"
                >
                  <TagLeftIcon boxSize="12px" as={FaBitcoin} />
                  <TagLabel> Bitcoin </TagLabel>
                </Tag>
              ))}
            </HStack>
            <br />
          </Box>
        </Box>
      </Box>
    </div>
  );
}
export default ProfileLayout1;
