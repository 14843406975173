import React from "react";            
import {            
 Box,           
 Heading,           
 Link,           
 Image,           
 Text,           
 Divider,           
 HStack,           
 Tag,           
 Wrap,           
 WrapItem,           
 SpaceProps,           
 useColorModeValue,           
 Container,           
 VStack,           
} from "@chakra-ui/react";   
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";


interface IBlogTags {            
     tags: Array<string>;       
     marginTop?: SpaceProps["marginTop"];       
}
    const BlogTags: React.FC<IBlogTags> = (props) => {        
       return (     
        <HStack spacing={2} marginTop={props.marginTop}>    
{props.tags.map((tag) => {    
       return (     
        <Tag size={"md"} variant="solid" colorScheme="orange" key={tag}>    
{tag}    
        </Tag>    
);
})}
        </HStack>    
);
};
interface BlogAuthorProps {            
     date: Date;       
     name: string;       
}
export const BlogAuthor: React.FC<BlogAuthorProps> = (props) => {             
       return (     
        <HStack marginTop="2" spacing="2" display="flex" alignItems="center">    
<Image    
borderRadius="full"    
boxSize="40px"    
src=" https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/ "  
alt={` Avatar of ${props.name} `}  
/>
<Text fontWeight="medium">{props.name}</Text>    
<Text> — </Text>  
<Text>{props.date.toLocaleDateString()}</Text>    
        </HStack>    
);
};
    const Blog1 = () => {        
       return (     
        <div style={{ backgroundImage: `url(${background})` }}>

        <Container maxW={"7xl"} p="12">    
        <Heading as="h1"> MetaGame Web3Skills Collaborative Gigs </Heading>  
        <Box    
marginTop={{ base: "1", sm: "5" }}    
display="flex"    
flexDirection={{ base: "column", sm: "row" }}    
justifyContent="space-between"    
>
        <Box    
display="flex"    
flex="1"    
marginRight="3"    
position="relative"    
alignItems="center"    
>
        <Box    
width={{ base: "100%", sm: "85%" }}    
zIndex="2"    
marginLeft={{ base: "0", sm: "5%" }}    
marginTop="5%"    
>
<Link textDecoration="none" _hover={{ textDecoration: "none" }}>    
<Image    
borderRadius="lg"    
// src={" https://bafybeiaoouahheahnfziiybpv7wuvxpthwmcip7m557mmxu3nlkzbki6oi.ipfs.infura-ipfs.io/ "
// src={" https://bafybeignubrsxqj4tzdgz5mqxf545r2fk77e5qhbgk67aacqys2dsufdsq.ipfs.infura-ipfs.io/ "  
// src={" https://bafybeia5uao63ppanvkyj7uxnwhmuxazrhnnrmzanblxfjlxkbozi3z57e.ipfs.infura-ipfs.io/ "  
// src={" https://bafybeihyswfympur6dq4qswdfq5kwb6e5a2wmi4t4gvxbmxksxhkyc4mhy.ipfs.infura-ipfs.io/ "
src={" https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "  
}
alt=" MetaGame "  
objectFit="contain"    
/>
        </Link>    
        </Box>    
        <Box zIndex="1" width="100%" position="absolute" height="100%">    
        <Box    
bgGradient={useColorModeValue(    
"radial(orange.600 1px, transparent 1px)",    
"radial(orange.300 1px, transparent 1px)"    
)}
backgroundSize="20px 20px"    
opacity="0.4"    
height="100%"    
/>
        </Box>    
        </Box>    
        <Box    
display="flex"    
flex="1"    
flexDirection="column"    
justifyContent="center"    
marginTop={{ base: "3", sm: "0" }}    
>
        <BlogTags tags={["Engineering", "Product"]} />    
        <Heading marginTop="1">    
<Link textDecoration="none" _hover={{ textDecoration: "none" }}>    
MetaGame Meta-Collab Gigs   
        </Link>    
        </Heading>    
<Text    
as="p"    
marginTop="2"    
color={useColorModeValue("white.700", "gray.200")}    
fontSize="lg"    
>
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </Text>    
        <BlogAuthor name="John Doe" date={new Date("2021-04-06T19:01:27Z")} />    
        </Box>    
        </Box>    
        <Heading as="h2" marginTop="5">    
Meta-Collab Connects Players to Gigs, and Gigs to Patrons.    
        </Heading>    
        <Divider marginTop="5" />    
        <Wrap spacing="30px" marginTop="5">    
        <WrapItem width={{ base: "100%", sm: "45%", md: "45%", lg: "30%" }}>    
        <Box w="100%">    
        <Box borderRadius="lg" overflow="hidden">    
<Link textDecoration="none" _hover={{ textDecoration: "none" }}>    
<Image    
transform="scale(1.0)"    
// src={" https://bafybeignubrsxqj4tzdgz5mqxf545r2fk77e5qhbgk67aacqys2dsufdsq.ipfs.infura-ipfs.io/ "  
// src={" https://bafybeia5uao63ppanvkyj7uxnwhmuxazrhnnrmzanblxfjlxkbozi3z57e.ipfs.infura-ipfs.io/ "  
src={" https://bafybeihyswfympur6dq4qswdfq5kwb6e5a2wmi4t4gvxbmxksxhkyc4mhy.ipfs.infura-ipfs.io/ "
// src={" https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/ "

}
alt=" MetaGame "  
objectFit="contain"    
width="100%"    
transition="0.3s ease-in-out"    
_hover={{    
     transform: "scale(1.05)",       
}}
/>
        </Link>    
        </Box>    
        <BlogTags tags={["Engineering", "Product"]} marginTop="3" />    
        <Heading fontSize="xl" marginTop="2">    
<Link textDecoration="none" _hover={{ textDecoration: "none" }}>    
MetaGame Web3Skills Collaborative Gigs   
        </Link>    
        </Heading>  
        <Heading fontSize="lg" marginTop="2">    
<Link textDecoration="none" _hover={{ textDecoration: "none" }}>    
Meta-Collab Connects Players to Gigs, and Gigs to Patrons.   
        </Link>    
        </Heading>    
<Text as="p" fontSize="md" marginTop="2">    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </Text>    
        <BlogAuthor    
name=" Player One "  
     date={new Date("2021-04-06T19:01:27Z")}       
/>
        </Box>    
        </WrapItem>    
        </Wrap>    
        <VStack paddingTop="40px" spacing="2" alignItems="flex-start">    
        <Heading color="lime" as="h2"> MetaCollab provides a complete contracting solution with built in dispute and resoultion using smart-contracts. </Heading>  
<Text as="p" fontSize="lg">    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </Text>    
<Text as="p" fontSize="lg">    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </Text>    
<Text as="p" fontSize="lg">    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </Text>    

        <Heading color="cyan" as="h2"> Use MetaCollab and Level-up your Web3 experience </Heading>  
<Text as="p" fontSize="lg">    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </Text>    
<Text as="p" fontSize="lg">    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </Text>    
<Text as="p" fontSize="lg">    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </Text>    

        </VStack>    
        </Container>    

        </ div>



);
};
export default Blog1;              
