import React from "react";
import {
  Box,
  Image,
  Link,
  Editable,
  EditableInput,
  EditablePreview,
  //  CircularProgress,
  //  CircularProgressLabel,
  //  Stack,
  HStack,
  Text,
} from "@chakra-ui/react";

// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

function Images1() {
  return (
        <div style={{ backgroundImage: `url(${background})` }}>

    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Image
          borderRadius="lg"
          width={{ md: 40 }}
          src=" https:bafybeialwa63jqrxffkz2lha3bflwdumqoctqdimezknsfrm5jdgyeqjm4.ipfs.infura-ipfs.io "
          alt=" MetaGame "
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
        <Text
          fontWeight="bold"
          textTransform="proper"
          fontSize="3xl"
          letterSpacing="wide"
          color="cyan"
        >
          Search for Player by Skills and Experience
        </Text>
        <Editable defaultValue=" Player gallery description">
          <EditablePreview />
          <EditableInput />
        </Editable>
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Find Players and Teams to Collaborate with on Gigs
        </Link>
        <br />
        {/* <Box boxSize="fit" align="right">    
<Image src=" https://bafybeididcw6gmg2ohgcu3pj4slvpkjx7zl3qqugmbbqp5bp6vqvgo3x4u.ipfs.infura-ipfs.io/"  alt=" Scott Stevenson " />
        </Box>    
        <Box boxSize="fit">    
<Image src=" https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/"  alt=" Anne McAllister " />
        </Box>    
        <Box boxSize="sm">    
<Image src=" https://bafybeididcw6gmg2ohgcu3pj4slvpkjx7zl3qqugmbbqp5bp6vqvgo3x4u.ipfs.infura-ipfs.io/"  alt=" Scott Stevenson " />
        </Box>    
        <Box boxSize="xs">    
<Image src=" https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/" alt=" Anne McAllister " />
        </Box>    
<br/>               
<br/>      
https://bafkreihexz5akqilywhl5urgd6wtfljyii4caoprnrdga7t3omkh3fqmmu.ipfs.infura-ipfs.io
https://bafkreifilnncnioxvgf5hwflpsnci5jh32gv7opvfraifuic7yt4gckfwe.ipfs.infura-ipfs.io
https://bafkreidxjurvsulbflygkun5oxa2pwp6lwauxomdytsqj2ajhpqtbeddiy.ipfs.infura-ipfs.io
https://bafkreicvr3bok3b7lybh5vvwibf6s5rob5virtheoqhrs4bmnrgm23a72m.ipfs.infura-ipfs.io
https://bafkreih7dj6sh2243ojlo24p37el7bxragyyqd3lwwuajkanjyz66ndk6q.ipfs.infura-ipfs.io
https://bafkreiatjtclts745uxynobadoq6gtnlnos3jgutdjcao3vsysd7mf7np4.ipfs.infura-ipfs.io
https://bafkreifh7usjrms5zzfnig3edae7tdrzw67eet74p4idl63ah3xmqp6yem.ipfs.infura-ipfs.io
https://bafkreiae7zkopxng2sm4gsr4pj5qsokho65qtij7mstbwr3lxgyh73izyq.ipfs.infura-ipfs.io
https://bafkreiglm4tsg5pikzzg7w5s7amwfigwzzz6viermwhcuqdgi6w33byidq.ipfs.infura-ipfs.io
https://bafkreietzncm6jndshuqzigbxryumxv2jnxkfbiqfpq7y5awelicved5su.ipfs.infura-ipfs.io
https://bafkreiegfgjfyhn3cncpf4k6dkfpjmsft4o63qze3ltwkw5c6hiryqlbiu.ipfs.infura-ipfs.io
https://bafkreifrptostnav6zpverufd3g4hkaunm7xxplpi77lexspa6yp4kqelu.ipfs.infura-ipfs.io
https://bafkreicgk2ikylg46bx6tdotgqpd754g2rnq4hig35mg2lknlkk5pdmgqe.ipfs.infura-ipfs.io
https://bafkreig76hgjnuxmsaqkhwqjqrw7sdaulfo2slesi3b36wn25nhgbbmhzm.ipfs.infura-ipfs.io
https://bafkreifl5g6wx3g3qyuk4bze7ldul4vwraz3kxo3gwvnsrmcnsanfze3nm.ipfs.infura-ipfs.io
https://bafkreihugvgra5xuwrze3m3xoriw4obu3matmykc7q5ti5vdajjipya3b4.ipfs.infura-ipfs.io
https://bafkreicgcfbp4f5l6i55efa73wz24zkat2ikpbzipzlsa2xycgylgwbppe.ipfs.infura-ipfs.io
https://bafkreiabcvvjalu5hhxpfd7zv65o7kwibwnupkcrqp5tv4njcyv6dikija.ipfs.infura-ipfs.io
https://bafkreigvvtpcvdzxycfvccfjir5jvikuqbx6gbklqf6g7hz2goj2ch2dlm.ipfs.infura-ipfs.io
https://bafkreibbykwbey3ssnqlnrntofgjguxt3huqmnbirrw4eusa7ytoy7pfii.ipfs.infura-ipfs.io
https://bafkreidxobulukh45c6viewt6if26kevhid2vfsfkhyjr5yaju4qejieda.ipfs.infura-ipfs.io

<br/>                */}

        <Box>
          <HStack>
            <Image
              borderRadius="full"
              boxSize="150px"
              src=" https://bafkreihexz5akqilywhl5urgd6wtfljyii4caoprnrdga7t3omkh3fqmmu.ipfs.infura-ipfs.io/ "
              alt=" MetaGame "
            />
            <Image
              borderRadius="full"
              boxSize="150px"
              src=" https://bafkreifilnncnioxvgf5hwflpsnci5jh32gv7opvfraifuic7yt4gckfwe.ipfs.infura-ipfs.io/ "
              alt=" MetaGame "
            />
            <Image
              borderRadius="full"
              boxSize="150px"
              src=" https://bafkreidxjurvsulbflygkun5oxa2pwp6lwauxomdytsqj2ajhpqtbeddiy.ipfs.infura-ipfs.io/ "
              alt=" MetaGame "
            />
            <Image
              borderRadius="full"
              boxSize="150px"
              src=" https://bafkreicvr3bok3b7lybh5vvwibf6s5rob5virtheoqhrs4bmnrgm23a72m.ipfs.infura-ipfs.io/ "
              alt=" MetaGame "
            />

            <Image
              borderRadius="full"
              boxSize="150px"
              src=" https://bafkreifrptostnav6zpverufd3g4hkaunm7xxplpi77lexspa6yp4kqelu.ipfs.infura-ipfs.io/ "
              alt=" MetaGame "
            />

            <Image
              borderRadius="full"
              boxSize="150px"
              src=" https://bafkreifl5g6wx3g3qyuk4bze7ldul4vwraz3kxo3gwvnsrmcnsanfze3nm.ipfs.infura-ipfs.io/ "
              alt=" MetaGame "
            />
            <Image
              borderRadius="full"
              boxSize="150px"
              src=" https://bafkreihugvgra5xuwrze3m3xoriw4obu3matmykc7q5ti5vdajjipya3b4.ipfs.infura-ipfs.io/ "
              alt=" MetaGame "
            />
            <Image
              borderRadius="full"
              boxSize="150px"
              src=" https://bafkreigvvtpcvdzxycfvccfjir5jvikuqbx6gbklqf6g7hz2goj2ch2dlm.ipfs.infura-ipfs.io/ "
              alt=" MetaGame "
            />
            <Image
              borderRadius="full"
              boxSize="150px"
              src=" https://bafkreidxobulukh45c6viewt6if26kevhid2vfsfkhyjr5yaju4qejieda.ipfs.infura-ipfs.io/ "
              alt=" MetaGame "
            />
          </HStack>
        </Box>
        <br />
        {/* <Stack direction="row">    
<Image    
boxSize="100px"    
objectFit="cover"    
src=" https://bafybeiguyjy4qwabtnovknmynr53fxhqfdfao5gj524bllxfcxiaa3pmz4.ipfs.infura-ipfs.io/ "  
alt=" MetaGame "  
/>
<Image    
boxSize="150px"    
objectFit="cover"    
src=" https://bafybeiguyjy4qwabtnovknmynr53fxhqfdfao5gj524bllxfcxiaa3pmz4.ipfs.infura-ipfs.io/ "  
alt=" MetaGame "  
/>
<Image boxSize="200px" src=" https://bafybeiguyjy4qwabtnovknmynr53fxhqfdfao5gj524bllxfcxiaa3pmz4.ipfs.infura-ipfs.io/ " alt=" Scott Stevenson " />
        </Stack>     */}
        <br />
        <Box>
          {/* <HStack>    
<Image src="gibbresh.png" fallbackSrc="https://via.placeholder.com/150" />    
<Image src="gibbresh.png" fallbackSrc="https://via.placeholder.com/150" />    
<Image src="gibbresh.png" fallbackSrc="https://via.placeholder.com/150" />    
<Image src="gibbresh.png" fallbackSrc="https://via.placeholder.com/150" />    
<Image src="gibbresh.png" fallbackSrc="https://via.placeholder.com/150" />    
        </HStack>     */}
        </Box>
        {/* <HStack>    
        <CircularProgress value={59} size="100px" thickness="4px" />    
        <CircularProgress value={30} color="orange.400" thickness="12px" />    
        <CircularProgress isIndeterminate color="green.300" />    
        <CircularProgress value={40} color="green.400">    
        <CircularProgressLabel>40%</CircularProgressLabel>    
        </CircularProgress>    
        </HStack>     */}
        <Text mt={2} color="gray.500">
          Getting a new game off the ground is hard work. Here are a few ways
          ways MetaGame participants are found.
        </Text>
      </Box>
    </Box>
    </ div>

  );
}
export default Images1;
