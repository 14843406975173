import React from "react";
import {
  useDisclosure,
  Button,
  Box,
  // Image,
  Icon,
  Link,
  // Editable,
  // EditableInput,
  // EditablePreview,
  // CircularProgress,
  // CircularProgressLabel,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  // HStack,
  Text,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

import { FaRegFlushed, FaRegGrimace, FaRegGrinAlt, FaRegGrinBeamSweat, FaRegGrinStars           
} from "react-icons/fa";   
function Drawer3() {
  const [size, setSize] = React.useState("md");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleClick = (newSize) => {
    setSize(newSize);
    onOpen();
  };
  const sizes = ["One", "Two", "Three", "Four", "Five"];
  return (
    <div style={{ backgroundImage: `url(${background})` }}>

    <Box p={2} display={{ md: "flex" }}>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 12 }}>
        <Link
          mt={1}
          color="yellow.100"
          display="block"
          fontSize="3xl"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Gigs can be locked by either party and deferred to a resolver.
        </Link>
        <div>    
<Icon as={ FaRegFlushed } ml={"50px"} mr={"100px"} p={2} w={20} h={20} color="orange.300" />  
<Icon as={ FaRegGrimace } mr={"100px"} p={2} w={20} h={20} color="orange.400" />  
<Icon as={ FaRegGrinBeamSweat } mr={"100px"} p={2}  w={20} h={20} color="yellow.400" />  
<Icon as={ FaRegGrinAlt } mr={"100px"} p={2}  w={20} h={20} color="yellow.300" />  
<Icon as={ FaRegGrinStars } mr={"60px"} p={2}  w={20} h={20} color="lime" />  
        </div>  
        <br />
        {sizes.map((size) => (
          <Button
            variant="outline"
            color="orange"
            onClick={() => handleClick(size)}
            key={size}
            m={1}
          >{`Resolve Step ${size} `}</Button>
        ))}
        <Drawer onClose={onClose} isOpen={isOpen} size={size}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader>{`${size} course contents`}</DrawerHeader>
            <DrawerBody>
              {size === "full"
                ? `Captured 😆 , refresh the page to leave or press 'Esc' key.`
                : null}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
        <br />
        <Text mt={2}  align="right" color="gray.300">
          Meta-Collab is a feature of Web3Skills and MetaGame.
        </Text>      </Box>
    </Box>
    </ div>

  );
}
export default Drawer3;
