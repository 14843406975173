import React from "react";
import {
  Box,
  Image,
  Text,
  Link,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
  FormHelperText,
  Stack,
  HStack,
  Radio,
  RadioGroup,
  Spinner,
  Input,
  Button,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";



// FORM DATA AND SENT TO DISCORD WEBHOOK
// Use the webhook id and token, to get these informations go to Server Settings>Integrations>Webhooks and click on the pretended webhook and copy the webhook URL. Paste the URL on google to see the webhook's ID and Token.

// const id = '';
// const token = '';
// const webhook = new Discord.WebhookClient(id, token);
// webhook.send('Your message');



// form = document.getElementById("submit-form");
// sucessMsg = document.getElementById("success");

// function sucess(event) {
// sucessMsg.style.display = "inline"
// event.preventDefault()
// console.log("form data:", getData())


// }

// function getData()
// {
// const formData = new FormData(form),
// ret = {};

// for(let [name, value] of formData.entries())
// ret[name] = value;

//  return ret;
//  } 


// form.addEventListener("submit" , sucess )


function FormLayout4() {
  return (
    <div style={{ backgroundImage: `url(${background})` }}>

    <Box p={4} display={{ md: "flex" }}>
      <Box flexShrink={0}>
        <Image
          borderRadius="lg"
          width={{ md: 40 }}
          src=" https://bafkreibazoeptlr4c5hrgdjzepthjm3iscwe56ojxcxrkorwufjqgfrpmu.ipfs.infura-ipfs.io
 "
          alt=" MetaGame "
        />
      </Box>
      <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize="2xl"
          letterSpacing="wide"
          color="lime"
        >
        Gig Creation Form
        </Text>
        <br />
        <Link
          mt={1}
          display="block"
          fontSize="lg"
          lineHeight="normal"
          fontWeight="semibold"
          href=" # "
        >
          Finding Members and Patrons for MetaGame
        </Link>
        <Text mt={2} color="gray.100">
          Getting a new organization off the ground takes patience. Here are
          some ways MetaGame members are found.
        </Text>
        <Stack spacing={3}>
          <Input 
          color="gray.900"  
          bg="green.800"
          variant="solid" 
          placeholder="Outline" />
          <Input
            variant="filled"
            placeholder=" Filled "
            bg="purple.800"
            color="yellow"
          />
          <Input color="gray.900"  variant="flushed" placeholder="Flushed" />
          <Input color="gray.900"  variant="unstyled" placeholder="Unstyled" />

        <FormControl as="fieldset">
          <FormLabel as="legend" color="gray.100"> Select the Gig Type </FormLabel>
          <RadioGroup defaultValue="Gig Type 1">
            <HStack spacing="24px">
              <Radio value="Value1"> Gig Type 1 </Radio>
              <Radio value="Value2"> Gig Type 2 </Radio>
              <Radio value="Value3"> Gig Type 3 </Radio>
              <Radio value="MetaGame"> MetaGame Wildcard </Radio>
            </HStack>
          </RadioGroup>
          <FormHelperText color="gray.100" >
            No payment required until the Gig starts.
          </FormHelperText>
        </FormControl>
        <br />
        <FormControl id="first-name" isRequired>
          <FormLabel> Funding Account (0x) </FormLabel>
          <Input color="white" placeholder="0x........." />
        </FormControl>
        <br />
        <FormControl id="state">
          <FormLabel> State of Residence (only use if located in the USA)</FormLabel>
          <Select placeholder="Select state">
            <option> Alabama (AL) </option>
            <option> Alaska (AK) </option>
            <option> Arizona (AZ) </option>
            <option> Arkansas (AR) </option>
            <option> California (CA) </option>
            <option> Colorado (CO) </option>
            <option> Connecticut (CT) </option>
            <option> Delaware (DE) </option>
            <option> District of Columbia (DC) </option>
            <option> Florida (FL) </option>
            <option> Georgia (GA) </option>
            <option> Hawaii (HI) </option>
            <option> Idaho (ID) </option>
            <option> Illinois (IL) </option>
            <option> Indiana (IN) </option>
            <option> Iowa (IA) </option>
            <option> Kansas (KS) </option>
            <option> Kentucky (KY) </option>
            <option> Louisiana (LA) </option>
            <option> Maine (ME) </option>
            <option> Maryland (MD) </option>
            <option> Massachusetts (MA) </option>
            <option> Michigan (MI) </option>
            <option> Minnesota (MN) </option>
            <option> Mississippi (MS) </option>
            <option> Missouri (MO) </option>
            <option> Montana (MT) </option>
            <option> Nebraska (NE) </option>
            <option> Nevada (NV) </option>
            <option> New Hampshire (NH) </option>
            <option> New Jersey (NJ) </option>
            <option> New Mexico (NM) </option>
            <option> New York (NY) </option>
            <option> North Carolina (NC) </option>
            <option> North Dakota (ND) </option>
            <option> Ohio (OH) </option>
            <option> Oklahoma (OK) </option>
            <option> Oregon (OR) </option>
            <option> Pennsylvania (PA) </option>
            <option> Rhode Island (RI) </option>
            <option> South Carolina (SC) </option>
            <option> South Dakota (SD) </option>
            <option> Tennessee (TN) </option>
            <option> Texas (TX) </option>
            <option> Utah (UT) </option>
            <option> Vermont (VT) </option>
            <option> Virginia (VA) </option>
            <option> Washington (WA) </option>
            <option> West Virginia (WV) </option>
            <option> Wisconsin (WI) </option>
            <option> Wyoming (WY) </option>
            <option> American Samoa (AS) </option>
            <option> Guam (GU) </option>
            <option> Northern Mariana Islands (MP) </option>
            <option> Puerto Rico (PR) </option>
            <option> Virgin Islands (VI) </option>
            <option> Armed Forces Africa (AE) </option>
            <option> Armed Forces Americas (AA) </option>
            <option> Armed Forces Canada (AE) </option>
            <option> Armed Forces Europe (AE) </option>
            <option> Armed Forces Middle East (AE) </option>
            <option> Armed Forces Pacific (AP) </option>
          </Select>
        </FormControl>
        <br />
        <FormControl id="californiacounty">
          <FormLabel> County (only use if located in California </FormLabel>
          <Select placeholder="Select county in California">
            <option> Alameda County </option>
            <option> Alpine County </option>
            <option> Amador County </option>
            <option> Butte County </option>
            <option> Calaveras County </option>
            <option> Colusa County </option>
            <option> Contra Costa County </option>
            <option> Del Norte County </option>
            <option> El Dorado County </option>
            <option> Fresno County </option>
            <option> Glenn County </option>
            <option> Humboldt County </option>
            <option> Imperial County </option>
            <option> Inyo County </option>
            <option> Kern County </option>
            <option> Kings County </option>
            <option> Lake County </option>
            <option> Lassen County </option>
            <option> Los Angeles County </option>
            <option> Madera County </option>
            <option> Marin County </option>
            <option> Mariposa County </option>
            <option> Mendocino County </option>
            <option> Merced County </option>
            <option> Modoc County </option>
            <option> Mono County </option>
            <option> Monterey County </option>
            <option> Napa County </option>
            <option> Nevada County </option>
            <option> Orange County </option>
            <option> Placer County </option>
            <option> Plumas County </option>
            <option> Riverside County </option>
            <option> Sacramento County </option>
            <option> San Benito County </option>
            <option> San Bernardino County </option>
            <option> San Diego County </option>
            <option> San Francisco County </option>
            <option> San Joaquin County </option>
            <option> San Luis Obispo County </option>
            <option> San Mateo County </option>
            <option> Santa Barbara County </option>
            <option> Santa Clara County </option>
            <option> Santa Cruz County </option>
            <option> Shasta County </option>
            <option> Sierra County </option>
            <option> Siskiyou County </option>
            <option> Solano County </option>
            <option> Sonoma County </option>
            <option> Stanislaus County </option>
            <option> Sutter County </option>
            <option> Tehama County </option>
            <option> Trinity County </option>
            <option> Tulare County </option>
            <option> Tuolumne County </option>
            <option> Ventura County </option>
            <option> Yolo County </option>
            <option> Yuba County </option>
          </Select>
        </FormControl>
        <br />

        <FormControl as="fieldset">
          <FormLabel as="legend"> Digital Currency Used in Gig </FormLabel>
          <RadioGroup defaultValue="MATIC">
            <HStack spacing="24px">
              <Radio value="Matic"> Polygon Matic </Radio>
              <Radio value="Ethereum"> Ethereum </Radio>
              <Radio value="DAI"> DAI </Radio>
              <Radio value="Other"> Other Digital Cuurency </Radio>

            </HStack>
          </RadioGroup>
          <FormHelperText>
            No payment required until the Gig starts.
          </FormHelperText>
        </FormControl>

        <br />
        <FormControl id="amount">
          <FormLabel> Amount </FormLabel>
          <NumberInput max={50} min={10}>
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </FormControl>
        <br />
        <br />

        </Stack>
        <FormControl id="email">
          <FormLabel> Email address (Optional)) </FormLabel>
          <Input type="email" />
          <FormHelperText> We'll never share your email. </FormHelperText>
        </FormControl>

        <br />
        <Button colorScheme="messenger" size="md" type="submit">
        Assemble Meta-Transaction
        </Button>

        <Stack justify="right" direction="row" spacing={4}>
          <Spinner
            thickness="2px"
            speed="2s"
            emptyColor="gray.200"
            color="pink.500"
            size="lg"
          />
          <Spinner
            thickness="2px"
            speed="3s"
            emptyColor="gray.200"
            color="blue.500"
            size="lg"
          />
          <Spinner
            thickness="2px"
            speed="4s"
            emptyColor="gray.200"
            color="purple.500"
            size="lg"
          />
        </Stack>
        <Stack justify="right" direction="row" spacing={4}>
          <Text mt={2} color="gray.100">
            Please wait while your transaction processes. It may take a while.
          </Text>
          
        </Stack>
      </Box>

    </Box>
</ div >    
  );
}
export default FormLayout4;
