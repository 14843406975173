import { ReactNode } from 'react';            
import {            
 Box,           
 Flex,           
 Heading,           
 Text,           
 Stack,           
 Container,           
 Avatar,           
 useColorModeValue,           
} from '@chakra-ui/react';      

// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

    const Testimonial = ({ children }: { children: ReactNode }) => {        
return <Box>{children}</Box>;    
};
    const TestimonialContent = ({ children }: { children: ReactNode }) => {        
       return (     
        <Stack    
bg={useColorModeValue('blue.900', 'purple.900')}    
boxShadow={'lg'}    
p={8}    
rounded={'xl'}    
align={'center'}    
pos={'relative'}    
_after={{    
     content: `""`,       
     w: 0,       
     h: 0,       
     borderLeft: 'solid transparent',       
borderLeftWidth: 16,    
     borderRight: 'solid transparent',       
     borderRightWidth: 16,       
     borderTop: 'solid',       
     borderTopWidth: 16,       
     borderTopColor: useColorModeValue('gray', 'gray.800'),       
     pos: 'absolute',       
     bottom: '-16px',       
     left: '50%',       
     transform: 'translateX(-50%)',       
}}>
{children}    
        </Stack>    
);
};
    const TestimonialHeading = ({ children }: { children: ReactNode }) => {        
       return (     
        <Heading as={'h3'} fontSize={'xl'} color={"white"}>    
{children}    
        </Heading>    
);
};
    const TestimonialText = ({ children }: { children: ReactNode }) => {        
       return (     
<Text    
textAlign={'center'}    
color={useColorModeValue('.600', 'gray.400')}    
fontSize={'sm'}>    
{children}    
        </Text>    
);
};
    const TestimonialAvatar = ({        
 src,           
name,    
title,    
}: {    
src: string;    
     name: string;       
     title: string;       
}) => {    
       return (     
        <Flex align={'center'} mt={8} direction={'column'}>    
        <Avatar src={src} alt={name} mb={2} />    
        <Stack spacing={-1} align={'center'}>    
<Text color={"gray.100"} fontWeight={600}>{name}</Text>    
<Text fontSize={'sm'} color={useColorModeValue('gray.600', 'gray.400')}>    
{title}    
        </Text>    
        </Stack>    
        </Flex>    
);
};

//  METAGAME IMAGES
// https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/
// https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/
// https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/
// https://bafybeiggmevfo3wujbvt3xqcvaudhrbs5wug7heigsxnnbkueburlefena.ipfs.infura-ipfs.io/
// https://bafybeiaj5zzzxd6sj6xahk5s75frcwnyo7kkfuyq3lxflflv2al45xebtu.ipfs.infura-ipfs.io/
// https://bafybeifyn3uk25iitgdmqz25a6xbkbwf72os2ssogayyllsrctm5ify23q.ipfs.infura-ipfs.io/
// https://bafybeiduvjqictrruk3zqbxnyep3egjmqqtosoksklvpm7cna3l7q7z4xy.ipfs.infura-ipfs.io/
// https://bafybeiaa4sm4arxmcpa3cxk4wav3nlvdjfry2eayba4sleg6m4qenyhe4y.ipfs.infura-ipfs.io/
// https://bafybeihyswfympur6dq4qswdfq5kwb6e5a2wmi4t4gvxbmxksxhkyc4mhy.ipfs.infura-ipfs.io/
// https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/
// https://bafybeiaule52hkimehkorjam6r52mkiyruhaqsq5bnfnggqrynbzfnduly.ipfs.infura-ipfs.io/
// https://bafybeia3mfrnztxelxacsci7a42fe4wjjq7mfakdm7a7percuzqizikmyq.ipfs.infura-ipfs.io/
// https://bafybeih4g5hqfjj2wwtqlzom7ci6pvxzv3rxlehnxd3i4ua66e5pckqp2q.ipfs.infura-ipfs.io/
// https://bafybeielkjkekw6a5ix65nz5tuw4qmfqcpeku63xwy6fzmpjbpi2w4g26m.ipfs.infura-ipfs.io/
// https://bafybeididcw6gmg2ohgcu3pj4slvpkjx7zl3qqugmbbqp5bp6vqvgo3x4u.ipfs.infura-ipfs.io/
// https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/
// https://bafybeihb465w6sxhpiqntihkpe5x24kkdcxvjvonlipgnvzhar6zqapefq.ipfs.infura-ipfs.io/
// https://bafybeib4lcuqh2iylaj6ywqxn4ccgpz5faiirppitodl5h7b6kmxnevppa.ipfs.infura-ipfs.io/
// https://bafybeihb465w6sxhpiqntihkpe5x24kkdcxvjvonlipgnvzhar6zqapefq.ipfs.infura-ipfs.io/



      function Testimonial1() {      
       return (     
        <div style={{ backgroundImage: `url(${background})` }}>
        {/* <Box bg={useColorModeValue('gray.500', 'gray.700')}>     */}
        <Box >    

        <Container maxW={'7xl'} py={16} as={Stack} spacing={12}>    
        <Stack spacing={0} align={'center'}>    
        <Heading color="gray.100" > MetaGame Meta-Collab Services  </Heading>  
<Text color="gray.100" > Three MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems. </Text>  
        </Stack>    
        <Stack    
direction={{ base: 'column', md: 'row' }}    
spacing={{ base: 10, md: 4, lg: 10 }}>    
        <Testimonial color="gray">    
        <TestimonialContent>    
        <TestimonialHeading color="gray"> Efficient Collaborating </TestimonialHeading>  
        <TestimonialText>    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </TestimonialText>    
        </TestimonialContent>    
        <TestimonialAvatar    
src={' https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/ '  
}
name={' Player One '}  
title={' Web3 Consulting – Web3Skills SmartLaw '}  
/>
        </Testimonial>    
        <Testimonial>    
        <TestimonialContent>    
        <TestimonialHeading> Intuitive Design </TestimonialHeading>  
        <TestimonialText>    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </TestimonialText>    
        </TestimonialContent>    
        <TestimonialAvatar    
src={' https://bafybeiaule52hkimehkorjam6r52mkiyruhaqsq5bnfnggqrynbzfnduly.ipfs.infura-ipfs.io/ '  
}
name={' Player Two '}  
title={' Lead Designer – MetaGame Player '}  
/>
        </Testimonial>    
        <Testimonial>    
        <TestimonialContent>    
        <TestimonialHeading> MetaGame Infrastructure </TestimonialHeading>  
        <TestimonialText>    
MetaGame provides value and service supporting the fourth industrial revolution. Web3 is a set of technologies that publicly interoperate through blockchain and node based computing systems.   
        </TestimonialText>    
        </TestimonialContent>    
        <TestimonialAvatar    
src={' https://bafybeielkjkekw6a5ix65nz5tuw4qmfqcpeku63xwy6fzmpjbpi2w4g26m.ipfs.infura-ipfs.io/ '  
}
name={' Player Three '}  
title={' Lead Developer – Web3Skills '}  
/>
        </Testimonial>    
        </Stack>    
        </Container>    
        </Box>
        </ div>
);
}
export default Testimonial1;              
