import React from "react";            
import {            
 Box,
 Image,           
 Text,           
 StackDivider,           
 VStack,           
} from "@chakra-ui/react";   
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";




      function GridLayout9() { 
              
        const property1 = {
                imageUrl:
                  " https://bafybeiduvjqictrruk3zqbxnyep3egjmqqtosoksklvpm7cna3l7q7z4xy.ipfs.infura-ipfs.io ",
                imageAlt: " Player Profile ",
                skills: 3,
                guilds: 2,
                title: "Each player receives a MyMeta profile and ...",
                formattedPrice: "    100 ",
                reviewCount: 34,
                rating: 4,
              };
        //       const property2 = {
        //         imageUrl:
        //           " https://bafybeiduvjqictrruk3zqbxnyep3egjmqqtosoksklvpm7cna3l7q7z4xy.ipfs.infura-ipfs.io ",
        //         imageAlt: " Player Profile ",
        //         skills: 3,
        //         guilds: 2,
        //         title: "Each player receives a MyMeta profile and ...",
        //         formattedPrice: "    100 ",
        //         reviewCount: 34,
        //         rating: 4,
        //       };
        //       const property3 = {
        //         imageUrl:
        //           " https://bafybeiduvjqictrruk3zqbxnyep3egjmqqtosoksklvpm7cna3l7q7z4xy.ipfs.infura-ipfs.io ",
        //         imageAlt: " Player Profile ",
        //         skills: 3,
        //         guilds: 2,
        //         title: "Each player receives a MyMeta profile and ...",
        //         formattedPrice: "    30 MATIC ",
        //         reviewCount: 34,
        //         rating: 4,
        //       };
              const property4 = {
                imageUrl:
                  " https://bafybeiaj5zzzxd6sj6xahk5s75frcwnyo7kkfuyq3lxflflv2al45xebtu.ipfs.infura-ipfs.io ",
                imageAlt: " Player Profile ",
                skills: 3,
                guilds: 2,
                title: "Each player receives a MyMeta profile and ...",
                formattedPrice: "    40 MATIC ",
                reviewCount: 34,
                rating: 4,
              };
              const property5 = {
                imageUrl:
                  " https://bafybeifyn3uk25iitgdmqz25a6xbkbwf72os2ssogayyllsrctm5ify23q.ipfs.infura-ipfs.io ",
                imageAlt: " Player Profile ",
                skills: 3,
                guilds: 2,
                title: "Each player receives a MyMeta profile and ...",
                formattedPrice: "    40 MATIC ",
                reviewCount: 34,
                rating: 4,
              };

       return (   
        <div style={{ backgroundImage: `url(${background})` }}>
  
        <VStack    
divider={<StackDivider borderColor="gray.200" />}    
spacing={4}    
align="stretch"    
>
        <Box h="40px" bg="purple">  
          
<Text mt={2} ml={3} color="white">    
Meta-Collab Text Card One
        <Image src={property1.imageUrl} alt={property1.imageAlt} />
        </Text>    

        </Box>    
        <Box h="40px" bg="blue">    
<Text mt={2} ml={3} color="white">    
Meta-Collab Text Card Two
   
        </Text>    
        <Image src={property4.imageUrl} alt={property4.imageAlt} />

        </Box>    
        <Box h="40px" bg="magenta">    
<Text mt={2} ml={3} color="white">    
Meta-Collab Text Card Three
   
        </Text>    
        <Image src={property5.imageUrl} alt={property5.imageAlt} />

        </Box>    
        </VStack>    
        </ div>    
);
}
export default GridLayout9;              
