import React from "react";
import {
  useDisclosure,
  Input,
  Button,
  Box,
  // Image,
  Link,
  // Editable,
  // EditableInput,
  // EditablePreview,
  // CircularProgress,
  // CircularProgressLabel,
  List,
  ListItem,
  ListIcon,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  // HStack,
  Text,
} from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";

import { 
  FaBalanceScale, 
  FaFingerprint,          
  FaGasPump,
  FaSearchDollar,
  FaUserCircle,
  FaBitcoin, 
  FaEthereum,
} from "react-icons/fa";

import {
  LockIcon,
  UnlockIcon,
} from "@chakra-ui/icons";

import {
  WiTime6,
} from "react-icons/wi";

function Drawer1() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();
  return (
    <div style={{ backgroundImage: `url(${background})` }}>

    <Box p={4} display={{ md: "flex" }}>

      <Box mt={{ base: 4, md: 0 }} ml={{ md: 48 }}>

        <Link
          mt={1}
          display="block"
          fontSize="xl"
          lineHeight="normal"
          fontWeight="semibold"
          color="yellow"
          href=" # "
        >
          Query the Gig's Details
        </Link>
        <br />
        <Button ref={btnRef} colorScheme="teal" onClick={onOpen}>
        Open the Tooling Station
        </Button>
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader> Create your account </DrawerHeader>
            <DrawerBody>
              <Input placeholder="Type here..." />
            </DrawerBody>
            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="blue"> Save </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
        <br />

        <Text mt={2} color="gray.100">
          Getting a new organization off the ground takes patience. Here are
          some ways MetaGame members are found.
        </Text>      
        
        
        
        
        </Box>
<Box>
<List spacing={4}>

<ListItem>
  <Text fontSize="lg" color="gray.100">
    {" "}
    <ListIcon as={FaFingerprint} w={12} h={12} color="green.300" />
    MetaGame provides value and service supporting the fourth
    industrial revolution. Web3 is a set of technologies that
    publicly interoperate through blockchain and node based
    computing systems.
  </Text>
</ListItem>

<ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={FaGasPump} w={12} h={12} color="blue.200" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={WiTime6} w={16} h={16} color="lime" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={LockIcon} w={12} h={12} color="red.400" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={UnlockIcon} w={12} h={12} color="lime" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>
            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={FaBalanceScale} w={12} h={12} color="blue.200" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>


            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={FaSearchDollar} w={12} h={12} color="green.200" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>

            <ListItem>
              <Text fontSize="lg" color="gray.100">
                {" "}
                <ListIcon as={FaUserCircle} w={12} h={12} color="cyan" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>



            <ListItem>
              <Text fontSize="lg" color="gray.100">
                <ListIcon as={FaBitcoin} w={12} h={12} color="orange.400" />
                <ListIcon as={FaEthereum} w={12} h={12} color="blue.400" />
                MetaGame provides value and service supporting the fourth
                industrial revolution. Web3 is a set of technologies that
                publicly interoperate through blockchain and node based
                computing systems.
              </Text>
            </ListItem>

</List>

</Box>

    </Box>
    </ div>

  );
}
export default Drawer1;
