import React from "react";
import { Grid, GridItem, Box, Image, Badge } from "@chakra-ui/react";
// import background from "./images/mg-bckg-01-1280w.png";
// import background from "./images/mg-bckg-01-400w.png";
// import background from "./images/mg-bckg-01-600w.png";
// import background from "./images/mg-bckg-01-800w.png";
// import background from "./images/mg-bckg-02-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280w.png";
// import background from "./images/mg-bckg-02-btp-1280x720.png";
// import background from "./images/mg-bckg-02-btp-1280x800.png";
// import background from "./images/mg-bckg-02-ptb-1280w.png";
// import background from "./images/mg-bckg-02-ptb-1280x720.png";
// import background from "./images/mg-bckg-02-ptb-1280x800.png";
// import background from "./images/mg-bckg-02r-1280w.png";
// import background from "./images/mg-bckg-03-1280w.png";
import background from "./images/mg-bckg-03-btp-1280w.png";
// import background from "./images/mg-bckg-03-btp-1280x720.png";
// import background from "./images/mg-bckg-03-ptb-1280w.png";
// import background from "./images/mg-bckg-03-ptb-1280x720.png";
// import background from "./images/mg-bckg-03r-1280w.png";
// import background from "./images/mg-bckg-04-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280w.png";
// import background from "./images/mg-bckg-04-btp-1280x720.png";
// import background from "./images/mg-bckg-04-ptb-1280w.png";
// import background from "./images/mg-bckg-04-ptb-1280x720.png";
// import background from "./images/mg-bckg-04r-1280w.png";
// import background from "./images/mg-bckg-05-1280w.png";
// import background from "./images/mg-bckg-06-1280w.png";
// import background from "./images/mg-bckg-06-b1-1280w.png";
// import background from "./images/mg-bckg-06-b2-1280w.png";
// import background from "./images/mg-bckg-06c-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280w.png";
// import background from "./images/mg-bckg-06c-btp-1280x720.png";
// import background from "./images/mg-bckg-06cr-1280w.png";
// import background from "./images/mg-bckg-07-1280w.png";
// import background from "./images/mg-bckg-08-1280w.png";
// import background from "./images/mg-bckg-09-1280w.png";
// import background from "./images/mg-bckg-09-600w.png";
// import background from "./images/mg-bckg-09a-1280w.png";
// import background from "./images/mg-bckg-09b-1280w.png";
// import background from "./images/mg-bckg-09c-1280w.png";
// import background from "./images/mg-flag-01-200w.png";
// import background from "./images/mg-flag-01.svg";
// import background from "./images/mg-gear-logo-200w.png";
// import background from "./images/mg-gear-logo.svg";
// import background from "./images/mg-hero-01-200w.png";
// import background from "./images/mg-hero-02-200w.png";
// import background from "./images/mg-hero-03-200w.png";
// import background from "./images/mg-hero-04-1000sq.png";
// import background from "./images/mg-hero-04-1200w.png";
// import background from "./images/mg-hero-04-800sq.png";
// import background from "./images/mg-hero-05-200w.png";
// import background from "./images/mg-hero-05-300w.png";
// import background from "./images/mg-hero-05-800w.png";
// import background from "./images/mg-hero-06-1200w.png";
// import background from "./images/mg-hero-07-1200w.png";
// import background from "./images/mg-hero-08-1200w.png";
// import background from "./images/mg-hero-09-1200w.png";
// import background from "./images/mg-hero-10-1200w.png";
// import background from "./images/mg-hero-10-200sq.png";
// import background from "./images/mg-hero-10-200w.png";
// import background from "./images/mg-icon-01-200w.png";
// import background from "./images/mg-icon-02-200w.png";
// import background from "./images/mg-icon-03-200w.png";
// import background from "./images/mg-icon-04-200w.png";
// import background from "./images/mg-icon-04a-200w.png";
// import background from "./images/mg-icon-05-200w.png";
// import background from "./images/mg-icon-06-200w.png";
// import background from "./images/mg-logo-01-200w.png";
// import background from "./images/mg-logo-02-200w.png";
// import background from "./images/mg-profile-2022-200w.png";
// import background from "./images/mg-profile-players-200w.png";
// import background from "./images/mg-bckg-12-1280w.png";
// import background from "./images/mg-bckg-12-1280x360.png";
// import background from "./images/mg-bckg-12-1280x1440.png";


// METAGAME IMAGES ON IPFS
// https://bafybeihv7easq4dgbqdc5rgpzh5vnopfvmbitqxqeipkh7rdiw5pgw2xjq.ipfs.infura-ipfs.io/ 
// https://bafybeignubrsxqj4tzdgz5mqxf545r2fk77e5qhbgk67aacqys2dsufdsq.ipfs.infura-ipfs.io
// https://bafybeih6j2bkbtr3p5ugxb6xvigqqziionmnh6sly2pwrqzbi6zgv3dwki.ipfs.infura-ipfs.io/
// https://bafybeidg2zafwojw52otuvrbyp4w34rv6gwbijl2fnjlzamu3edqg7thy4.ipfs.infura-ipfs.io/
// https://bafybeiexrfhhdlx3ric5v7yjez6pfexp4vhpszoq4l5ywdsg4mav4ifjcq.ipfs.infura-ipfs.io/
// https://bafybeiggmevfo3wujbvt3xqcvaudhrbs5wug7heigsxnnbkueburlefena.ipfs.infura-ipfs.io/
// https://bafybeiaj5zzzxd6sj6xahk5s75frcwnyo7kkfuyq3lxflflv2al45xebtu.ipfs.infura-ipfs.io/
// https://bafybeifyn3uk25iitgdmqz25a6xbkbwf72os2ssogayyllsrctm5ify23q.ipfs.infura-ipfs.io/
// https://bafybeiduvjqictrruk3zqbxnyep3egjmqqtosoksklvpm7cna3l7q7z4xy.ipfs.infura-ipfs.io/
// https://bafybeiaa4sm4arxmcpa3cxk4wav3nlvdjfry2eayba4sleg6m4qenyhe4y.ipfs.infura-ipfs.io/
// https://bafybeihyswfympur6dq4qswdfq5kwb6e5a2wmi4t4gvxbmxksxhkyc4mhy.ipfs.infura-ipfs.io/
// https://bafybeigemdjp2j24wyqljbwc7nisg5l2m4h3r5ob5sa4eq4quml4egl3ae.ipfs.infura-ipfs.io/
// https://bafybeiaule52hkimehkorjam6r52mkiyruhaqsq5bnfnggqrynbzfnduly.ipfs.infura-ipfs.io/
// https://bafybeia3mfrnztxelxacsci7a42fe4wjjq7mfakdm7a7percuzqizikmyq.ipfs.infura-ipfs.io/
// https://bafybeih4g5hqfjj2wwtqlzom7ci6pvxzv3rxlehnxd3i4ua66e5pckqp2q.ipfs.infura-ipfs.io/
// https://bafybeielkjkekw6a5ix65nz5tuw4qmfqcpeku63xwy6fzmpjbpi2w4g26m.ipfs.infura-ipfs.io/
// https://bafybeididcw6gmg2ohgcu3pj4slvpkjx7zl3qqugmbbqp5bp6vqvgo3x4u.ipfs.infura-ipfs.io/
// https://bafybeiadlckn4fdpst6sgl72yhil7o2v5nvb7ao6ystpovnqk6ictughky.ipfs.infura-ipfs.io/
// https://bafybeihb465w6sxhpiqntihkpe5x24kkdcxvjvonlipgnvzhar6zqapefq.ipfs.infura-ipfs.io/
// https://bafybeib4lcuqh2iylaj6ywqxn4ccgpz5faiirppitodl5h7b6kmxnevppa.ipfs.infura-ipfs.io/
// https://bafybeihb465w6sxhpiqntihkpe5x24kkdcxvjvonlipgnvzhar6zqapefq.ipfs.infura-ipfs.io/
// https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io/


import { ImStarFull } from "react-icons/im";
function GridLayout3() {
  const property1 = {
    imageUrl:
      " https://bafybeiaa4sm4arxmcpa3cxk4wav3nlvdjfry2eayba4sleg6m4qenyhe4y.ipfs.infura-ipfs.io ",
    imageAlt: " Player Profile ",
    skills: 3,
    guilds: 2,
    title: "Each player receives a MyMeta profile and ...",
    formattedPrice: "    100 ",
    reviewCount: 34,
    rating: 4,
  };
  const property2 = {
    imageUrl:
      " https://bafybeiduvjqictrruk3zqbxnyep3egjmqqtosoksklvpm7cna3l7q7z4xy.ipfs.infura-ipfs.io ",
    imageAlt: " Player Profile ",
    skills: 3,
    guilds: 2,
    title: "Each player receives a MyMeta profile and ...",
    formattedPrice: "    20 MATIC ",
    reviewCount: 34,
    rating: 4,
  };
  const property3 = {
    imageUrl:
      " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io ",
    imageAlt: " Player Profile ",
    skills: 3,
    guilds: 2,
    title: "Each player receives a MyMeta profile and ...",
    formattedPrice: "    20 MATIC ",
    reviewCount: 34,
    rating: 4,
  };
  const property4 = {
    imageUrl:
      " https://bafybeiaa4sm4arxmcpa3cxk4wav3nlvdjfry2eayba4sleg6m4qenyhe4y.ipfs.infura-ipfs.io ",
    imageAlt: " Player Profile ",
    skills: 3,
    guilds: 2,
    title: "Each player receives a MyMeta profile and ...",
    formattedPrice: "    100 ",
    reviewCount: 34,
    rating: 4,
  };
  const property5 = {
    imageUrl:
      " https://bafybeiduvjqictrruk3zqbxnyep3egjmqqtosoksklvpm7cna3l7q7z4xy.ipfs.infura-ipfs.io ",
    imageAlt: " Player Profile ",
    skills: 3,
    guilds: 2,
    title: "Each player receives a MyMeta profile and ...",
    formattedPrice: "    20 MATIC ",
    reviewCount: 34,
    rating: 4,
  };
  const property6 = {
    imageUrl:
      " https://bafybeia5nuk33s2e7szg2wb6fewqs4xmae3r2r7yd27ouoiaymhz3m2j6y.ipfs.infura-ipfs.io ",
    imageAlt: " Player Profile ",
    skills: 3,
    guilds: 2,
    title: "Each player receives a MyMeta profile and ...",
    formattedPrice: "    20 MATIC ",
    reviewCount: 34,
    rating: 4,
  };

  return (
        <div style={{ backgroundImage: `url(${background})` }}>

    <Grid templateColumns="repeat(5, 1fr)" gap={4}>




      <GridItem
        colSpan={2}
        h="100%"
        w="100%"
        bg="gray.700"
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        ml="2"
        mr="2"
        overflow="hidden"
      >
        <Image src={property1.imageUrl} alt={property1.imageAlt} />
        <Box p="6">
          <Box d="flex" alignItems="baseline">
            <Badge borderRadius="full" px="2" colorScheme="orange">
              New Look
            </Badge>
            <Box
              color="yellow.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {property1.skills} skills &bull; {property1.guilds} guilds
            </Box>
          </Box>
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {property1.title}
          </Box>
          <Box color="yellow.500">
            {property1.formattedPrice}
            <Box as="span" color="white.600" fontSize="sm">
              per service
            </Box>
          </Box>
          <Box d="flex" mt="2" alignItems="center">
            {Array(5)
              .fill("")
              .map((_, i) => (
                <ImStarFull
                  key={i}
                  color={i < property1.rating ? "teal.500" : "gray.300"}
                />
              ))}
            <Box as="span" ml="2" color="gray.600" fontSize="sm">
              {property1.reviewCount} reviews
            </Box>
          </Box>
        </Box>
        <Box
          as="button"
          fontSize="xs"
          ml="2"
          mb="2"
          borderRadius="md"
          bg="yellowgreen"
          color="black"
          px={4}
          h={6}
        >
          Contact Player
        </Box>
      </GridItem>




      <GridItem
      
        colStart={3}
        colEnd={5}
        h="100%"
        w="200%"
        bg="gray.700"
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        ml="2"
        mr="2"
        overflow="hidden"
      >
        <Image src={property2.imageUrl} alt={property2.imageAlt} />
        <Box p="6">
          <Box d="flex" alignItems="baseline">
            <Badge borderRadius="full" px="2" colorScheme="orange">
              New Look
            </Badge>
            <Box
              color="yellow.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {property2.skills} skills &bull; {property2.guilds} guilds guilds
            </Box>
          </Box>
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {property2.title}
          </Box>
          <Box color="yellow.500">
            {property2.formattedPrice}
            <Box as="span" color="white.600" fontSize="sm">
              per service
            </Box>
          </Box>
          <Box d="flex" mt="2" alignItems="center">
            {Array(5)
              .fill("")
              .map((_, i) => (
                <ImStarFull
                  key={i}
                  color={i < property2.rating ? "teal.500" : "gray.300"}
                />
              ))}
            <Box as="span" ml="2" color="gray.600" fontSize="sm">
              {property2.reviewCount} reviews
            </Box>
          </Box>
        </Box>
        <Box
          as="button"
          fontSize="xs"
          ml="2"
          mb="2"
          borderRadius="md"
          bg="yellowgreen"
          color="black"
          px={4}
          h={6}
        >
          Contact Player
        </Box>
      </GridItem>



      <GridItem
        colStart={6}
        colEnd={8}
        h="100%"
        w="200%"
        bg="gray.700"
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        ml="2"
        mr="2"
        overflow="hidden"
      >
        <Image src={property3.imageUrl} alt={property3.imageAlt} />
        <Box p="6">
          <Box d="flex" alignItems="baseline">
            <Badge borderRadius="full" px="2" colorScheme="orange">
              New Look
            </Badge>
            <Box
              color="yellow.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {property3.skills} skills &bull; {property3.guilds} guilds guilds
            </Box>
          </Box>
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {property3.title}
          </Box>
          <Box color="yellow.500">
            {property3.formattedPrice}
            <Box as="span" color="white.600" fontSize="sm">
              per service
            </Box>
          </Box>
          <Box d="flex" mt="2" alignItems="center">
            {Array(5)
              .fill("")
              .map((_, i) => (
                <ImStarFull
                  key={i}
                  color={i < property3.rating ? "teal.500" : "gray.300"}
                />
              ))}
            <Box as="span" ml="2" color="gray.600" fontSize="sm">
              {property3.reviewCount} reviews
            </Box>
          </Box>
        </Box>
        <Box
          as="button"
          fontSize="xs"
          ml="2"
          mb="2"
          borderRadius="md"
          bg="yellowgreen"
          color="black"
          px={4}
          h={6}
        >
          Contact Player
        </Box>
      </GridItem>

      <GridItem
        colSpan={2}
        h="100%"
        w="100%"
        bg="gray.700"
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        ml="2"
        mr="2"
        overflow="hidden"
      >
        <Image src={property4.imageUrl} alt={property4.imageAlt} />
        <Box p="6">
          <Box d="flex" alignItems="baseline">
            <Badge borderRadius="full" px="2" colorScheme="orange">
              New Look
            </Badge>
            <Box
              color="yellow.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {property4.skills} skills &bull; {property4.guilds} guilds
            </Box>
          </Box>
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {property4.title}
          </Box>
          <Box color="yellow.500">
            {property4.formattedPrice}
            <Box as="span" color="white.600" fontSize="sm">
              per service
            </Box>
          </Box>
          <Box d="flex" mt="2" alignItems="center">
            {Array(5)
              .fill("")
              .map((_, i) => (
                <ImStarFull
                  key={i}
                  color={i < property4.rating ? "teal.500" : "gray.300"}
                />
              ))}
            <Box as="span" ml="2" color="gray.600" fontSize="sm">
              {property4.reviewCount} reviews
            </Box>
          </Box>
        </Box>
        <Box
          as="button"
          fontSize="xs"
          ml="2"
          mb="2"
          borderRadius="md"
          bg="yellowgreen"
          color="black"
          px={4}
          h={6}
        >
          Contact Player
        </Box>
      </GridItem>




      <GridItem
      
        colStart={3}
        colEnd={5}
        h="100%"
        w="200%"
        bg="gray.700"
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        ml="2"
        mr="2"
        overflow="hidden"
      >
        <Image src={property5.imageUrl} alt={property5.imageAlt} />
        <Box p="6">
          <Box d="flex" alignItems="baseline">
            <Badge borderRadius="full" px="2" colorScheme="orange">
              New Look
            </Badge>
            <Box
              color="yellow.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {property5.skills} skills &bull; {property5.guilds} guilds guilds
            </Box>
          </Box>
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {property5.title}
          </Box>
          <Box color="yellow.500">
            {property5.formattedPrice}
            <Box as="span" color="white.600" fontSize="sm">
              per service
            </Box>
          </Box>
          <Box d="flex" mt="2" alignItems="center">
            {Array(5)
              .fill("")
              .map((_, i) => (
                <ImStarFull
                  key={i}
                  color={i < property5.rating ? "teal.500" : "gray.300"}
                />
              ))}
            <Box as="span" ml="2" color="gray.600" fontSize="sm">
              {property5.reviewCount} reviews
            </Box>
          </Box>
        </Box>
        <Box
          as="button"
          fontSize="xs"
          ml="2"
          mb="2"
          borderRadius="md"
          bg="yellowgreen"
          color="black"
          px={4}
          h={6}
        >
          Contact Player
        </Box>
      </GridItem>



      <GridItem
        colStart={6}
        colEnd={8}
        h="100%"
        w="200%"
        bg="gray.700"
        maxW="sm"
        borderWidth="1px"
        borderRadius="lg"
        ml="2"
        mr="2"
        overflow="hidden"
      >
        <Image src={property6.imageUrl} alt={property6.imageAlt} />
        <Box p="6">
          <Box d="flex" alignItems="baseline">
            <Badge borderRadius="full" px="2" colorScheme="orange">
              New Look
            </Badge>
            <Box
              color="yellow.500"
              fontWeight="semibold"
              letterSpacing="wide"
              fontSize="xs"
              textTransform="uppercase"
              ml="2"
            >
              {property6.skills} skills &bull; {property6.guilds} guilds guilds
            </Box>
          </Box>
          <Box
            mt="1"
            fontWeight="semibold"
            as="h4"
            lineHeight="tight"
            isTruncated
          >
            {property6.title}
          </Box>
          <Box color="yellow.500">
            {property6.formattedPrice}
            <Box as="span" color="white.600" fontSize="sm">
              per service
            </Box>
          </Box>
          <Box d="flex" mt="2" alignItems="center">
            {Array(5)
              .fill("")
              .map((_, i) => (
                <ImStarFull
                  key={i}
                  color={i < property6.rating ? "teal.500" : "gray.300"}
                />
              ))}
            <Box as="span" ml="2" color="gray.600" fontSize="sm">
              {property6.reviewCount} reviews
            </Box>
          </Box>
        </Box>
        <Box
          as="button"
          fontSize="xs"
          ml="2"
          mb="2"
          borderRadius="md"
          bg="yellowgreen"
          color="black"
          px={4}
          h={6}
        >
          Contact Player
        </Box>
      </GridItem>


    </Grid>
    </ div >    

  );
}
export default GridLayout3;
